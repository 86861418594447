import React, {useEffect, useRef, useState} from 'react';
import ErrorBoundry from '../error-boundry';
import ReactTooltip from 'react-tooltip';
import TickerLine from '../ticker-line';
import TemplatePage from './page-template';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StatistikWrapper from "../statistick-wrapper";
import Table from "../table";
import StatistikService from "../../services/statistik-service";
import Loader from "../loader";
import Echo from "laravel-echo";
import {useSelector} from "react-redux";

setInterval(() => {
    window.location.reload()
}, 1000 * 60 * 30); // each 30 minutes


const StatistikPage = () => {

    const statisticService = new StatistikService()

    const [officeData, setOfficeData] = useState()
    const [leaderBoard, setLeaderBoard] = useState()
    const [board, setBoard] = useState()
    const [officeBoard, setOfficeBoard] = useState()
    const [officeLeaderBoard, setOfficeLeaderBoard] = useState()
    const [rookieBoard, setRookieBoard] = useState()
    const [rookieLeaderBoard, setRookieLeaderBoard] = useState()
    const [loading, setLoading] = useState(true)
    const [agentTipsBoard, setAgentTipsBoard] = useState()
    const [agentTipsLeaderBoard, setAgentTipsLeaderBoard] = useState()
    const [agentRateLeaderBoard, setAgentRateLeaderBoard] = useState()
    const [agentLoggedInLeaderBoardMonthly, setAgentLoggedInLeaderBoardMonthly] = useState()
    const [agentLoggedInLeaderBoardAnnual, setAgentLoggedInLeaderBoardAnnual] = useState()

    const isOfficeMode = useSelector((state) => state.officeMode);

    useEffect(async () => {
        setLoading(true);
        await getTableData();
    }, [isOfficeMode]);

    const sliderEl = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 30000, // each 0,5 minutes
        // autoplaySpeed: 5000,
        onSwipe: () => {
            sliderEl.current.slickPause();
        },
    };

    const getTableData = async () => {
        // Office Board
        const month = statisticService.getCurrentSvedenMonth().substring(0, 3)
        const year = (new Date()).getFullYear() - 1;

        await statisticService.getTable()
            .then(res => {
                setOfficeData({
                    tableHeadings: ['Omsättning', 'Budget', 'Kundmöte', 'Uppdrag', 'Sålda', 'Till\u00A0Salu', 'Kommande', 'Visningar'],
                    data: res.singleOfficeReport
                })
                setBoard({
                    tableHeadings: ['Omsättning', 'Mot budget', 'Antal sålda', 'Möten', 'fg. år'],
                    data: res.agentsMonthly
                })
                setLeaderBoard({
                    tableHeadings: ['Omsättning'],
                    data: res.agentsAnnual
                })
                setAgentLoggedInLeaderBoardMonthly({
                    tableHeadings: ['Omsättning', 'Mot budget', 'Antal sålda', 'Möten', 'fg. år'],
                    data: res.loggedInAgentReportMonthly
                })
                setAgentLoggedInLeaderBoardAnnual({
                    tableHeadings: ['Omsättning'],
                    data: res.loggedInAgentReportAnnual
                })
                setAgentTipsBoard({
                    tableHeadings: ['TIPSTOPPEN ' + statisticService.getCurrentSvedenMonth().toUpperCase()],
                    data: res.agentTipsReportMonthly
                })
                setAgentTipsLeaderBoard({
                    tableHeadings: ['Tipstoppen'],
                    data: res.agentTipsReportAnnual
                })
                setOfficeBoard({
                    tableHeadings: ['Omsättning', 'Mot budget', 'Antal sålda', 'Möten', `${month} ${year}`],
                    data: res.officesMonthly
                })
                setOfficeLeaderBoard({
                    tableHeadings: ['Omsättning'],
                    data: res.officesAnnual
                })
                setRookieBoard({
                    tableHeadings: ['Omsättning', 'Mot budget', 'Antal sålda', 'Möten', 'fg. år'],
                    data: res.rookiesMonthly
                })
                setRookieLeaderBoard({
                    tableHeadings: ['Omsättning'],
                    data: res.rookiesAnnual
                })
                setAgentRateLeaderBoard({
                    tableHeadings: ["NPS Score", "Antal omdömen"],
                    data: res.agentRatesReport
                })
                setLoading(false)
            })
            .catch(err => {
                console.log('Get leader board table error', err);
            })
    }

    useEffect(async () => {
        const options = {
            'broadcaster': 'pusher',
            'key': '23132212777888',
            'cluster': 'mt1',
            'forceTLS': true,
            'encrypted': false,
            'wsHost': 'live-backend.saljtavla.svenskamaklarhuset.se',
            'wsPort': 6001,
            'wssPort': 6001,
            'disableStats': true,
            'enabledTransports': ['ws', 'wss']
        };

        const echo = new Echo(options);
        echo.channel('events')
            .listen('RefreshReportDataEvent', async (e) => {
                await getTableData();
            });

        await getTableData();
    }, []);

    let additionalImages = localStorage.getItem('carouselImages');

    if (additionalImages !== "undefined") {
        additionalImages = JSON.parse(additionalImages);
    } else {
        additionalImages = [];
    }

    const curDate = new Date();

    const hideStartDate = new Date('2024-12-06');
    const targetDate = new Date('2025-01-01');

    return (

        <TemplatePage>
            {loading ? <Loader/> : <>
                <div className='statistik-main'>
                    <div
                        className='container'
                    >
                        <ErrorBoundry>
                            <Slider ref={sliderEl} {...settings}>
                                {
                                    curDate < hideStartDate || curDate >= targetDate ? <StatistikWrapper key="agents_top" tables={{
                                        tableLg: <Table heading={{
                                            title: "TOPPLISTA MÄKLARE ",
                                            span: 6
                                        }} tableData={board} tableType="board"/>,
                                        tableSm: <Table heading={{title: "TOPPLISTA HELÅR", span: 3}}
                                                        tableData={leaderBoard}
                                                        tableType="leader_board"/>
                                    }}/> : ""
                                }

                                {
                                    curDate < hideStartDate || curDate >= targetDate ? (
                                        !isOfficeMode &&
                                        <StatistikWrapper key="agents_top_logged" tables={{
                                            tableLg: <Table heading={{
                                                title: "Min placering ",
                                                span: 6
                                            }} tableData={agentLoggedInLeaderBoardMonthly} tableType="logged_in_monthly"/>,
                                            tableSm: <Table heading={{title: "Min placering HELÅR", span: 3}}
                                                            tableData={agentLoggedInLeaderBoardAnnual}
                                                            tableType="logged_in_annual"/>
                                        }}/>
                                    ) : ""
                                }

                                { curDate < hideStartDate || curDate >= targetDate ? <StatistikWrapper key="offices_top" tables={{
                                    tableLg: <Table heading={{title: "Topplista Kontor", span: 3}}
                                                    tableData={officeBoard}
                                                    tableType="office_board"/>,
                                    tableSm: <Table heading={{title: "TOPPLISTA HELÅR", span: 2}}
                                                    tableData={officeLeaderBoard}
                                                    tableType="office_leader_board"/>
                                }}/> : ""}
                                { curDate < hideStartDate || curDate >= targetDate ? <StatistikWrapper key="agent_top_tips" tables={{
                                    tableLg: <Table heading={{title: "Tipstoppen", span: 6}} tableData={agentTipsBoard}
                                                    tableType="agent_tips_board"/>,
                                    tableSm: <Table heading={{title: "TOPPLISTA HELÅR", span: 3}}
                                                    tableData={agentTipsLeaderBoard}
                                                    tableType="agent_tips_leader_board"/>
                                }}/> : ""}

                                { curDate < hideStartDate || curDate >= targetDate ?
                                    (
                                        officeData && officeData.data.map((item, index) => {
                                                return <StatistikWrapper key={index} tables={{
                                                    tableLg: <Table
                                                        heading={{title: item.name.replace('Svenska Mäklarhuset', ''), span: 3}}
                                                        tableData={{
                                                            tableHeadings: ['Omsättning', 'Budget', 'Möten', 'Tips', 'Uppdrag', 'Sålda', 'Till Salu', 'Kommande', 'Visningar'],
                                                            data: item
                                                        }}
                                                        tableType="single_office_data"/>,
                                                }}/>
                                            }
                                        )
                                    ) : "" }

                                {/*{ curDate < hideStartDate || curDate >= targetDate ? <StatistikWrapper key="rookies" tables={{*/}
                                {/*    tableLg: <Table heading={{*/}
                                {/*        title: "ROOKIES " + statisticService.getCurrentSvedenMonth(),*/}
                                {/*        span: 6*/}
                                {/*    }}*/}
                                {/*                    tableData={rookieBoard}*/}
                                {/*                    tableType="rookie_board"/>,*/}
                                {/*    tableSm: <Table heading={{tiyle: "TOPPLISTA HELÅR", span: 3}}*/}
                                {/*                    tableData={rookieLeaderBoard}*/}
                                {/*                    tableType="rookie_leader_board"/>*/}
                                {/*}}/> : ""}       */}
                                { curDate < hideStartDate || curDate >= targetDate ?  <section className="statistik-wrapper additionalImages">
                                    <img src="/rookies_reapiring.png"/>
                                    </section> : ""}
                                { curDate < hideStartDate || curDate >= targetDate ? <StatistikWrapper key="agentsRate" tables={{
                                    tableLg: <Table heading={{title: "NPS Nöjdast kunder", span: 3}}
                                                    tableData={agentRateLeaderBoard}
                                                    tableType="agents_rates"/>
                                }}/> : ""}

                                {additionalImages && additionalImages.map((item, index) => {
                                        if (item.is_shown) {
                                            return <section className="statistik-wrapper additionalImages">
                                                <img src={item.image}/>
                                            </section>
                                        }
                                    }
                                )}
                            </Slider>
                        </ErrorBoundry>
                    </div>
                </div>
                <TickerLine/>
                <ReactTooltip/>
            </>}
        </TemplatePage>
    )
}

export default StatistikPage;
